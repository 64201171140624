.title {
  padding: 24px 16px 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: 22px;
}

.list {
  padding: 16px 12px;
  border: 1px solid #dcdcdc;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border-top: none;
  background-color: #f6f6f6;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;

  li {
    display: flex;
    align-items: center;
    gap: 6px;
    list-style-type: none;
  }
}
