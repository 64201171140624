@import 'styles/mixin.scss';

.aboutModalContainer {
  @include modalContainer;

  .aboutModalContent {
    @include modalContent;

    .aboutHeaderContainer {
      display: flex;
      justify-content: space-between;

      .aboutHeaderTitle {
        @include textBodyMedium;
        color: $graphite;
      }

      .aboutCloseIcon {
        cursor: pointer;
      }
    }

    .aboutStep {
      @include textBodySmall;
      color: $gray;
      margin: 19px 0 25px 0;
    }

    .aboutImage {
      margin-bottom: 25px;
      svg {
        width: 100%;
        height: auto;
      }
    }

    .aboutTitle {
      @include textBodyMedium;
      color: $darkGray;
      margin-bottom: 8px;
    }

    .aboutDescription {
      @include textBodySmall;
      color: $gray;
      padding-bottom: 25px;
      border-bottom: 1px solid $lightGray;
    }

    .aboutButtonContainer {
      margin-top: 25px;
      display: flex;
      justify-content: flex-end;
    }
  }
}
