@import 'styles/mixin.scss';

.salesFormSection {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .salesFormContainer {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 48px;
    width: 50%;
    padding: 0 40px;

    .salesFormContent {
      display: flex;
      flex-direction: column;
      gap: 32px;
      white-space: pre-wrap;
      max-width: 390px;
      margin: 0 auto;

      .salesFormTitle {
        @include textHeader2;
      }

      .salesFormSubtitle {
        @include textBodyMedium;
      }

      .salesFormDescription {
        @include textBodyRegular;

        span {
          @include textBodyBold;
          color: $accentViolet;
        }
      }
    }
  }
  .salesFormBtns {
    display: flex;
    flex-direction: column;
    max-width: 209px;
    width: 100%;
    gap: 16px;
  }

  .salesFormContainerRight {
    background: url('/assets/images/salesFormBg.png') no-repeat center;
    background-size: cover;
    position: relative;
    width: 50%;
    height: 100%;
  }

  .iconClose {
    position: absolute;
    top: 40px;
    right: 40px;
    cursor: pointer;
  }
}
