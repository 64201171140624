.progressBarContainer {
  width: 100%;
  background-color: #f3f3f3;
  overflow: hidden;
}

.progressBarUploaded {
  height: 2px;
  background-color: white;
}

.progressBarError {
  height: 2px;
  background-color: #e40f0f;
}
