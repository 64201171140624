@import 'styles/mixin.scss';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 70%;
    height: auto;
    border-radius: 24px;
  }
}
