@import 'styles/mixin.scss';

.navigationContainer {
  display: flex;
  gap: 18px;

  .navigationItem {
    @include textButtonMedium;
    text-decoration: none;
    color: $gray;
    text-align: center;
    padding: 12px 6px;

    &:not(.active):hover {
      padding: 0;
      p {
        border-radius: 8px;
        background-color: $lightGrayBackground;
        padding: 3px 6px;
        margin: 9px 0px;
      }
    }

    &.active {
      color: $accentViolet;
      margin-bottom: -1.5px;
      border-bottom: 2px solid $accentViolet;
    }
  }
}
