@import 'styles/mixin.scss';

.container {
  display: flex;
  flex-direction: row;
  flex: 1;

  .content,
  .preview {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .formStyles {
    display: flex;
    flex-direction: column;
    padding: 40px 48px 16px 48px;
    overflow: auto;

    .title {
      @include textBodyMedium;
      color: $darkGray;
    }

    .label {
      @include textBodyMedium;
      padding: 16px 0 4px 16px;
    }

    .description {
      @include textBodySmall;
      color: $gray;
      padding: 0px 0 12px 16px;
      width: 100%;
      max-width: 350px;
    }
  }

  .separator {
    margin-bottom: 24px;
  }

  .preview {
    display: flex;
    flex: 1;
    background-color: $lightGrayBackground;
    justify-content: center;
    align-items: center;

    svg {
      transform: scale(1.2);

      @media (max-height: 750px), (max-width: 1500px) {
        transform: scale(1);
      }
    }
  }
}
