@import 'styles/mixin.scss';

.testContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  flex: 1;

  .test {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 40px;
    gap: 24px;

    .copyContainer {
      margin-top: -55px;
      width: 452px;
      height: 286px;
      .title {
        @include textBodyMedium;
        color: $graphite;
      }

      .description {
        @include textBodyRegular;
        color: $gray;
        padding: 4px 0 24px 0;
      }

      .buttonContainer {
        margin-top: 32px;
        display: flex;
        justify-content: space-between;
      }

      @media (max-width: 1275px) {
        width: 400px;
      }
    }
  }

  .testIconContainer {
    svg {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 55%;
      height: auto;

      @media (min-width: 1475px) {
        width: 50%;
      }
    }

    @media (max-width: 1275px) {
      display: none;
    }
  }

  .preview {
    display: flex;
    width: 50%;
    padding-top: 80px;
    background-color: $lightGrayBackground;
    justify-content: center;

    @media screen and (max-width: 1200px) {
      justify-content: flex-start;
    }
  }
}
