@import 'styles/mixin.scss';

.wrapper {
  .modalFooter {
    .divider {
      height: 1px;
      background-color: $lightGray;
      margin: 24px 0;
    }

    .modalActionsWrapper {
      display: flex;
      justify-content: flex-end;
      gap: 16px;

      button {
        height: 100%;
      }

      button:first-child {
        max-height: 22px;
      }

      button:last-child {
        width: 100%;
        max-width: 118px;
        display: flex;
        justify-content: space-evenly;
      }
    }
  }

  .modalTitle {
    @include textBodyRegular;
    font-weight: 500;
  }

  .description {
    @include textBodySmall;
    max-width: 335px;
    margin-bottom: 24px;
    color: $gray;
  }

  .uploaderProperties {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    padding: 0 8px;

    p {
      @include textCaptionRegular;
      color: $gray;
    }
  }

  .uploadedTitle {
    @include textBodyMedium;
    padding: 8px;
    border-radius: 8px;
    background-color: $lightGrayBackground;
    font-weight: 400;
  }

  .cardWrapper {
    max-height: 156px;
    padding: 0 12px;
    overflow: auto;
  }
}

.formats {
  width: 320px;
}
