@import 'styles/mixin.scss';

.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: $Z_INDEX_MODAL;

  .contentStyles {
    background-color: $white;
    padding: 24px;
    border-radius: 24px;
    max-width: 518px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    max-height: 90vh;
    overflow-y: auto;

    .titleStyles {
      @include textHeader4;
      margin-bottom: 8px;
      color: $graphite;
      font-weight: 500;
    }

    .inputsGroup {
      .inputClass {
        display: flex;
        margin-bottom: 16px;

        .iconCloseContainer {
          display: flex;
          align-items: center;
          padding-bottom: 15px;
          padding-left: 8px;

          svg {
            cursor: pointer;
          }
        }
      }
    }

    .addPersonContainer {
      border-bottom: 1px solid $lightGray;
      padding-bottom: 24px;
    }

    .messageStyles {
      @include textBodyRegular;
      color: $gray;
      margin-bottom: 24px;
    }

    .buttonsGroup {
      margin-top: 24px;
      justify-content: flex-end;
      gap: 8px;
      display: flex;
    }
  }
}
