@import 'styles/mixin.scss';

.detailsContentConainer {
  margin-top: 24px;
  border-radius: 8px;
  border: 1px solid $lightGray;
  padding: 24px 32px;

  .detailsContentHeader {
    display: flex;
    justify-content: space-between;
    padding-bottom: 24px;
    border-bottom: 1px solid $lightGray;

    .detailsContentHeaderTitle {
      @include textBodyMedium;
      color: $darkGray;
      align-self: center;
    }
  }

  .detailsContentDataConainer {
    .detailsContentDataLabel {
      @include textButtonLarge;
      color: $darkGray;
      margin: 16px 0;
    }

    .detailsContentDataContent {
      display: flex;
      border-radius: 8px;
      border: 1px solid $lightGray;
      padding: 16px;
      align-items: center;

      svg {
        margin-right: 16px;
      }

      .detailsContentDataContentLength {
        @include textBodySmallMedium;
        margin-right: 5px;
        color: $darkGray;
      }

      .detailsContentDataContentDescription {
        @include textBodySmall;
        color: $gray;
      }
    }
  }
}
