@import 'styles/mixin.scss';

.container {
  z-index: $Z_INDEX_MODAL;
  position: absolute;
  top: 44px;
  right: 64px;
  background-color: $white;
  padding: 24px;
  border-radius: 12px;
  width: 252px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 10px 20px 0px rgba(68, 68, 68, 0.15);

  > p {
    margin-left: 16px;
  }

  .userName {
    @include textBodySmallMedium;
  }

  .companyName {
    @include textCaptionRegular;
    color: $gray;
    margin-bottom: 8px;
  }

  .separator {
    background-color: $lightGray;
    height: 1px;
    width: 100%;
    margin: 8px 0;
  }

  .linksGroup {
    // margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 4px;
  }

  .link {
    @include textBodySmallMedium;
    appearance: none;
    outline: none;
    cursor: pointer;
    border: 0;
    color: $gray;
    background-color: transparent;
    text-decoration: none;
    margin: 4px 0;
    padding: 6px 8px;
    gap: 8px;
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    border-radius: 8px;

    svg {
      width: 24px;
      height: 24px;
    }

    &:hover,
    &.activeLink {
      background-color: $lightGrayBackground;
    }
  }
}
