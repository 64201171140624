@mixin modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: $Z_INDEX_MODAL;
}

@mixin modalContent {
  background-color: $white;
  padding: 40px;
  border-radius: 12px;
  max-width: 556px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  overflow-y: auto;
}
