@import 'styles/mixin.scss';

.dropzoneWrapper {
  margin-bottom: 14px;
  border-radius: 12px;
  border: 1px dashed $accentViolet;
  background-color: $lightViolet;
  cursor: pointer;

  &.dropzoneDisabled {
    cursor: default;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .dropzoneTitle {
    @include textButtonMedium;
    color: $accentViolet;
  }
}
