@import 'styles/mixin.scss';

.container {
  max-width: 488px;
  padding: 16px;
  border-radius: 8px;
  border: 1.5px solid $lightGray;

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .previewWrapper {
    border: 1px dashed $lightGray;
    border-radius: 8px;
    margin-right: 16px;
    position: relative;

    .preview {
      cursor: pointer;
      height: 48px;
      width: 48px;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      margin: 3px;
    }

    svg {
      height: 24px;
      width: 24px;
    }

    .pickerStyles {
      position: absolute;
      top: 50%;
      left: 101%;
      z-index: 1;
      transform: translatey(-50%);
    }
  }

  .content {
    .info {
      @include textBodySmallMedium;
      color: $darkGray;
      margin: 0;
    }

    .subinfo {
      @include textBodySmall;
      color: $gray;
      margin: 0;
    }
  }
}
