@import 'styles/mixin.scss';

.buttonStyles {
  border-radius: 10px;
  color: $white;
  border: 0;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 8px 12px;

  p {
    @include textButtonMedium;
  }

  &.button__large {
    height: 44px;
    padding: 8px 12px;
    border-radius: 12px;
    p {
      @include textButtonLarge;
      width: max-content;
    }

    svg {
      width: 24px;
      height: 24px;
      margin-left: -2px;
      margin-right: 8px;
    }
  }

  &.button__medium {
    width: fit-content;
    padding: 6px 12px;
    height: 36px;
    border-radius: 10px;
    p {
      @include textButtonMedium;
      width: max-content;
    }

    svg {
      width: 20px;
      height: 20px;
      margin-left: -2px;
      margin-right: 6px;
    }
  }

  &.button__medium-wide {
    @extend .button__medium;
    width: 100%;
  }

  &.button__small {
    min-width: 0px;
    width: fit-content;
    padding: 4px 8px;
    height: 24px;
    border-radius: 6px;

    p {
      @include textCaptionMedium;
      width: max-content;
    }

    svg {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
  }

  &.button__primary {
    background: #5e36ff;
    color: #fff;

    &:hover {
      background: #4521d4;
      color: #f6f6f6;
    }
  }
  &.button__secondary {
    background: #efebff;
    color: #5e36ff;
    border: 1px solid #5e36ff;

    &:hover {
      background: #dcd3ff;
      color: #5e36ff;
    }
  }
  &.button__tertiary {
    background: transparent;
    border: none;
    color: #5e36ff;

    &:hover {
      background: #efebff;
    }
  }
  &.button__warning {
    background: #e40f0f;
    border: none;
    color: #f6f6f6;

    &:hover {
      background: #c40d0d;
    }
  }

  &.button__info {
    background: #efebff;
    border: none;
    color: #5e36ff;

    &:hover {
      background: #dcd3ff;
    }
  }

  &.button__transparent {
    background: #fff;
    border: none;
    color: #777;

    &:disabled {
      background: #fff;
      color: #777;

      &:hover {
        background: #fff;
      }
    }
  }

  &.separator {
    margin-bottom: 16px;
  }

  &:disabled,
  &.button__primary:disabled,
  &.button__secondary:disabled,
  &.button__tertiary:disabled,
  &.button__warning:disabled,
  &.button__info:disabled {
    cursor: default;
    border: none;
    background-color: $disabledGray;
    color: $white;

    &:hover {
      background-color: $disabledGray;
    }
  }

  svg {
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }
}
