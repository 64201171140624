@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Manrope';
  src: url(../assets/fonts//Manrope-VariableFont_wght.ttf);
  font-weight: 400;
}

*,
::before,
::after {
  box-sizing: border-box;
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: 'Inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* 
px / 16 = rem
font-size: 8px // 0.5rem
font-size: 9px // 0.5625rem
font-size: 10px // 0.625rem
font size: 11px // 0.6875rem 
font-size: 12px // 0.75rem
font-size: 13px // 0.8125rem
fon-size: 14px // 0.875rem
font-size: 15px // 0.9375rem
font size: 16px // 1rem 
font size: 17px // 1.0625rem 
font-size: 18px // 1.125rem
font-size: 19px // 1.1875rem
font-size: 20px // 1.25rem
font-size: 22px // 1.375rem
font size: 24px // 1.5rem 
font-size: 26px // 1.625rem
font-size: 27px // 1.6875rem
font-size: 28px // 1.75rem
font-size: 30px // 1.875rem
font-size: 32px // 2rem
font-size: 37px // 2.3125rem
font-size: 40px/ 2.5rem
font-size: 42px // 2.625rem
*/

body {
  scrollbar-width: thin;
}

*::-webkit-scrollbar-track {
  background-color: #fff;
}

*::-webkit-scrollbar {
  width: 4px;
  border-radius: 4px;
}

*::-webkit-scrollbar-thumb {
  background-color: #bebebe;
  border-radius: 4px;
}
