@import './breakpoints.scss';
@import './colors.scss';
@import './stylesConstants.scss';
@import './animations';
@import './components';

@mixin textHeader1 {
  font-family: 'Inter';
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 72px;
  letter-spacing: -3.3px;
}

@mixin textHeader2 {
  font-family: 'Inter';
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 54px;
  letter-spacing: -1.4px;
}

@mixin textHeader3 {
  font-family: 'Inter';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.6px;
}

@mixin textHeader4 {
  font-family: 'Inter';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0px;
}

@mixin textBodyRegular {
  font-family: 'Manrope';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.5px;
}

@mixin textBodyMedium {
  @include textBodyRegular;
  font-weight: 500;
}

@mixin textBodyBold {
  @include textBodyRegular;
  font-weight: 700;
}

@mixin textBodySmall {
  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.4px;
}

@mixin textBodySmallMedium {
  @include textBodySmall;
  font-weight: 500;
}

@mixin textButtonLarge {
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.1px;
}

@mixin textButtonMedium {
  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.4px;
}

@mixin textCaptionRegular {
  font-family: 'Inter';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.5px;
}

@mixin textCaptionMedium {
  @include textCaptionRegular;
  font-weight: 500;
}

@mixin preTitle {
  font-family: 'Inter';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 2.5px;
  text-transform: uppercase;
}

// DO ZMIANY
@mixin textCaptionBold {
  @include textCaptionRegular;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  letter-spacing: -0.4px;
}

@mixin subtitle {
  font-family: 'Inter';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: -0.4px;
}
