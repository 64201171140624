@import './breakpoints.scss';
@import './colors.scss';

.app {
  display: flex;
  flex: 1;
  height: 100vh;
  overflow: hidden;

  @media (max-width: $TABLET) {
    flex-direction: column;
  }
}

[data-test-id='supportx-techbot'] > div:first-child {
  inset: auto 50px 80px auto;
  z-index: 9;
}
