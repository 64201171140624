@import 'styles/mixin.scss';

.tooltipContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .tooltipText {
    @include textBodySmall;
    position: absolute;
    padding: 16px;
    border-radius: 8px;
    background: #353535;
    box-shadow: 0px 10px 20px 0px rgba(68, 68, 68, 0.15);
    color: #fff;
    text-align: center;
    z-index: 10;
    top: 17px;
    right: 10px;
    width: 185px;
  }
}
