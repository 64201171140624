@import 'styles/mixin.scss';

.knowledgeListHeader {
  background-color: $lightGrayBackground;
  margin-top: 32px;
  height: 56px;
  padding: 16px;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 0.7fr 0.7fr 0.1fr;
  gap: 32px;
  border: 1px solid $lightGray;
  border-bottom: none;
  border-radius: 8px 8px 0 0;

  .knowledgeListHeaderItem {
    @include textBodySmallMedium;
    display: flex;
    gap: 10px;
    color: $gray;
  }
}

.knowledgeList {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 0.7fr 0.7fr 0.1fr;
  gap: 32px;
  border: 1px solid $lightGray;

  svg {
    margin: 16px;
  }

  .knowledgeListItem {
    padding: 16px 0px 0px 16px;
    height: 56px;
    @include textBodyRegular;
    color: $gray;
    flex-direction: column;

    &:first-child {
      padding: 9px 16px;
    }

    .knowledgeListItemTitle {
      @include textBodySmall;
      color: $darkGray;
    }

    .knowledgeListItemDescription {
      @include textBodySmall;
      color: $gray;
      max-width: 21vw;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @media (max-width: 1300px) {
        max-width: 15vw;
      }
    }
  }
}

.knowledgeEmpty {
  display: flex;
  border: 1px solid $lightGray;
  text-align: center;
  justify-content: center;
  padding: 120px 0;

  .knowledgeEmptyContent {
    @include textBodyRegular;
    max-width: 420px;
    color: $gray;
  }
}
