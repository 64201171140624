@import 'styles/mixin.scss';

.container {
  display: flex;
  flex-direction: row;
  flex: 1;

  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .formStyles {
    display: flex;
    flex-direction: column;
    padding: 16px 48px;
    overflow: auto;

    .title {
      @include textBodyMedium;
      color: $darkGray;
      margin: 24px 0 16px 16px;
    }

    .label {
      @include textBodyMedium;
      color: $darkGray;
      padding: 16px 0 16px 16px;

      &:first-of-type {
        padding-top: 0;
      }
    }
  }

  .separator {
    margin-bottom: 24px;
  }

  .preview {
    display: flex;
    flex: 1;
    padding-top: 80px;
    background-color: $lightGrayBackground;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 1200px) {
      justify-content: flex-start;
    }
  }
}
