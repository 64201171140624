@import 'styles/mixin.scss';

.authCredentialsModalContainer {
  @include modalContainer;

  .authCredentialsModalContent {
    @include modalContent;

    .authCredentialsHeaderContainer {
      display: flex;
      justify-content: space-between;

      .authCredentialsHeaderTitle {
        @include textBodyMedium;
        color: $graphite;
      }

      .authCredentialsCloseIcon {
        cursor: pointer;
      }
    }

    .authCredentialsStep {
      @include textBodySmall;
      color: $gray;
      margin: 19px 0 25px 0;
    }

    .authCredentialsInputContainer {
      display: flex;
      flex-direction: column;
      gap: 5px;
      padding-bottom: 24px;
      border-bottom: 1px solid $lightGray;

      & > div {
        max-width: 100%;
      }
    }

    .authCredentialsButtonContainer {
      margin-top: 25px;
      display: flex;
      justify-content: flex-end;
      gap: 16px;
    }
  }
}
