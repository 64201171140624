@import 'styles/mixin.scss';

.wrapper {
  .modalFooter {
    .divider {
      height: 1px;
      background-color: $lightGray;
      margin: 24px 0;
    }

    .modalActionsWrapper {
      display: flex;
      justify-content: flex-end;
      gap: 16px;
    }
  }

  .modalTitle {
    @include textBodyRegular;
    font-weight: 500;
  }

  .description {
    @include textBodySmall;
    max-width: 335px;
    margin-bottom: 24px;
    color: $gray;
  }

  .uploaderProperties {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    padding: 0 8px;

    p {
      @include textCaptionRegular;
      color: $gray;
    }
  }

  .uploadedImagesTitle {
    @include textBodyMedium;
    padding: 8px;
    border-radius: 8px;
    background-color: $lightGrayBackground;
    font-weight: 400;
  }

  .imageCardWrapper {
    max-height: 156px;
    padding: 0 12px;
    overflow: auto;
  }
}
