@import 'styles/mixin.scss';

.inputContainer {
  position: relative;
  margin-top: 8px;
  margin-bottom: 22px;
  align-items: center;
  display: flex;
  max-width: 444px;
  height: 48px;
  box-sizing: border-box;

  &:focus-within .labelStyles {
    @include textBodySmallMedium;
    transform: translate(0, -23px);
    color: $accentViolet;
    background-color: $white;
  }

  .labelStyles {
    @include textBodyRegular;
    display: flex;
    position: absolute;
    pointer-events: none;
    transform: translate(0, 0);
    transform-origin: top left;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 10ms;
    color: $gray;
    left: 14px;
    padding: 0 4px;

    &.labelFilled {
      @include textBodySmallMedium;
      transform: translate(0, -23px);
      color: $accentViolet;
      background-color: $white;
    }

    &.labelVisibleStyles {
      @include textBodySmallMedium;
      transform: translate(0, -23px);
      background-color: $white;
    }

    &.labelError {
      background-color: $white;
      color: $errorRed;
    }

    &.disabledLabel {
      color: $darkGray;
    }
  }

  .selectStyles {
    appearance: none;
    cursor: pointer;
    background-color: transparent;
  }

  .inputStyles {
    @include textBodyRegular;
    box-sizing: border-box;
    border: none;
    border-radius: 8px;
    height: 48px;
    padding: 1px 16px 0;
    width: 444px;
    box-shadow: none;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    border: 1.5px solid $lightGray;

    &:focus,
    &:focus-visible {
      outline: 0;
      border-color: $accentViolet;
      transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

      + .labelStyles.labelVisibleStyles {
        color: $accentViolet;
      }
    }

    &.inputError {
      border-color: $errorRed;
    }

    &.inputWithIcon {
      padding-right: 46px;
    }

    &:disabled {
      background-color: $lightGrayBackground;
    }
  }

  .errorTextStyles,
  .infoTextStyles {
    @include textBodySmallMedium;
    position: absolute;
    bottom: -20px;
    left: 16px;
  }

  .errorTextStyles {
    color: $errorRed;
  }

  .infoTextStyles {
    color: $gray;
  }

  .iconEyeStyles {
    cursor: pointer;
    position: absolute;
    right: 10px;
    margin-top: 1px;
    padding: 4px 6px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.fullContainer {
    width: 100%;

    .inputStyles {
      width: 100%;
    }

    .errorTextStyles,
    .infoTextStyles {
      left: 0;
    }
  }

  &.textareaStyles {
    height: 68px;

    .inputStyles {
      height: 68px;
      padding: 13px 16px 5px;
      resize: none;
    }

    .labelStyles {
      &.labelVisibleStyles {
        transform: translate(0, -33px);
      }
    }
  }

  .chevronDown {
    position: absolute;
    right: 16px;
  }

  .inputSuffixContainer {
    @include textBodyRegular;
    margin-left: 16px;
    color: $darkGray;
  }
}
