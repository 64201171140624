@import 'styles/mixin.scss';

.dropdownContent {
  font-size: 16px !important;
  font-weight: 400 !important;

  li {
    padding: 12px !important;

    &:hover {
      background-color: $lightGrayBackground !important;
    }

    svg {
      width: 20px !important;
      height: 20px !important;
      margin-right: 8px !important;
    }
  }
}
