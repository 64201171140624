@import 'styles/mixin.scss';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 80px);

  .formStyles {
    display: flex;
    flex-direction: column;
    padding: 20px 40px 0;
    width: 100%;

    .dataSourcesInfoContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 24px;
      border-radius: 12px;
      background: $lightGrayBackground;
      padding: 16px 26px;

      .dataSourcesInfoDescription {
        @include textBodySmall;
        color: $gray;
        width: 100%;
        max-width: 478px;

        .dataSourcesInfoTitle {
          @include textBodyMedium;
          margin-bottom: 8px;
          color: $darkGray;
        }
      }

      .dataSourcesDetailsContainer {
        display: flex;
        max-width: 672px;

        @media (max-width: $LAPTOP_MIN) {
          flex-wrap: wrap;
        }

        .dataSourcesDetail {
          width: 168px;
          display: flex;
          flex-direction: column;
          align-items: center;
          border-left: 1px solid $lightGray;

          .dataSourcesDetailValue {
            @include textBodyMedium;
            color: $darkGray;
          }

          .dataSourcesDetailCount {
            @include textCaptionRegular;
            color: $gray;
            display: flex;
            gap: 4px;

            .dataSourcesDetailCountAdded {
              padding-right: 6px;
              border-right: 1px solid $gray;
            }
          }
        }
      }
    }

    .navigationContainer {
      border-bottom: 1.5px solid $lightGray;
      margin: 16px 0 18px;
      display: flex;
      gap: 18px;

      .navigationItem {
        @include textButtonMedium;
        text-decoration: none;
        color: $gray;
        text-align: center;
        padding: 6px 0px;
        cursor: pointer;

        .navigationItemText {
          padding: 3px 6px;
        }

        &:not(.active):hover {
          .navigationItemText {
            border-radius: 8px;
            background-color: $lightGrayBackground;
          }
        }

        &.active {
          color: $accentViolet;
          margin-bottom: -1.5px;
          border-bottom: 2px solid $accentViolet;
        }
      }
    }

    .urlRow {
      display: flex;
      width: 100%;
      max-width: 600px;
      gap: 22px;
      align-items: center;
      height: auto;
      margin-bottom: 12px;

      & > div {
        margin: 0;
        max-width: none;
      }
    }

    .separator {
      margin-bottom: 24px;
    }
  }
}

.checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  height: 24px;

  label {
    margin-right: 10px;
  }

  div {
    position: relative;
    left: 0;

    svg {
      width: 20px;
      height: 20px;
      margin-bottom: 4px;
    }
  }
}
