@import 'styles/mixin.scss';

.noteContainer {
  position: relative;
  border-radius: 8px;
  background: $lightViolet;
  padding: 16px;

  .noteTitle,
  .noteDescription {
    @include textBodyRegular;
  }

  .noteTitle {
    font-weight: 500;
    color: $darkGray;
  }
  .noteDescription {
    color: $gray;
    margin: 8px 0;
  }

  .noteCloseButton {
    position: absolute;
    right: 16px;
    cursor: pointer;
    svg {
      height: 16px;
      width: 16px;
    }
  }
}
