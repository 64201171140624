$Z_INDEX_MODAL: 15;

$TOP_BAR_HEIGHT: 65px;

$MEDIUM_LAPTOPS: 1200;
$SMALL_LAPTOPS: 1024;

$REGULAR_RIGHT_PANEL: 300;
$BIG_RIGHT_PANEL: 400;

$REGULAR_RIGHT_PANEL_PX: $REGULAR_RIGHT_PANEL + px;
$BIG_RIGHT_PANEL_PX: $BIG_RIGHT_PANEL + px;

$Z_INDEX_INPUT_IN_SELECTED_CONVERSATION: 1;
$Z_INDEX_TOP_INPUT_GRADIENT: 1;
$Z_INDEX_REPLY: 1;
$Z_INDEX_EMOJI_POPUP: 2;
$Z_INDEX_CHAT_LIST_MENU: 2;
$Z_INDEX_TYPING: 3;
$Z_INDEX_PAYWALL_WRAPPER: 3;
$Z_INDEX_MENTION: 4;
$Z_INDEX_CONTEXT_MENU: 4;
$Z_INDEX_TOP_MENU_IN_NOTIFICATION_EDGE_PANEL: 4;
$Z_INDEX_POPUPS: 5;
$Z_INDEX_QUICK_REPLY: 5;
$Z_INDEX_LEFT_MENU: 10;
$Z_INDEX_TOP_MENU: 11;
$Z_INDEX_IMAGE_PREVIEW_MODAL: 12;

$CONTEXT_MENU_WIDTH: 218;

:export {
  Z_INDEX_CONTEXT_MENU: $Z_INDEX_CONTEXT_MENU;
  CONTEXT_MENU_WIDTH: $CONTEXT_MENU_WIDTH;
}
