@import 'styles/mixin.scss';

.techbotDeployContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;

  .container {
    margin: 40px;
    width: 492px;
    height: 286px;
    padding: 24px;
    border-radius: 12px;

    .title {
      @include textBodyMedium;
      color: $graphite;
    }

    .description {
      @include textBodyRegular;
      color: $gray;
      padding: 4px 0 24px 0;
    }

    .buttonContainer {
      display: flex;
      justify-content: space-between;
      margin-bottom: 32px;
      gap: 16px;
    }

    .emailSection {
      margin-bottom: 32px;

      button {
        margin-top: 32px;
      }
    }

    .codeContainer {
      padding: 13px 16px 11px 16px;
      border-radius: 4px;
      border: 1px solid $lightGray;
      background: $lightGrayBackground;
      position: relative;
      margin-bottom: 24px;

      .codeTitle {
        @include textBodySmallMedium;
        position: absolute;
        top: -9px;
        left: 16px;
        background-color: $white;
        padding: 0 4px;
      }

      .code {
        @include textBodyRegular;
        color: $gray;
      }
    }
  }
  .preview {
    display: flex;
    flex: 1;
    max-width: 50%;
    padding-top: 80px;
    background-color: $lightGrayBackground;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 1250px) {
      justify-content: flex-start;
    }
  }
}
