.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex; 
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(3px);
  background-color: rgba(255,255,255,0.6);
  z-index: 2;
}