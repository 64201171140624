@mixin shimmer(
  $color1: #f6f6f6 8%,
  $color2: #e0e0e0 18%,
  $color3: #f6f6f6 33%
) {
  animation: shimmerKeyframes 1s infinite linear;
  background: linear-gradient(to right, $color1, $color2, $color3);
  background-size: 200%;
  border-radius: 12px;
}

@keyframes shimmerKeyframes {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}
