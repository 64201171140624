@import 'styles/mixin.scss';

.statusContainer {
  display: flex;
  align-items: center;

  .status {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: $lightBlue;
    color: $accentViolet;
    width: fit-content;
    padding: 4px 6px;
    height: 24px;
    border-radius: 6px;

    .statusDescription {
      @include textCaptionMedium;
      color: $mainGreen;
    }

    svg {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }

    .iconLoader {
      display: none;
    }
  }

  .statusLoading {
    display: flex;
    justify-content: center;
    background: $lightViolet;
    color: $accentViolet;
    width: fit-content;
    padding: 4px 6px;
    height: 24px;
    border-radius: 6px;

    .statusDescription {
      @include textCaptionMedium;
    }

    & > div {
      width: 14px;
      height: 14px;
      margin-right: 8px;

      svg {
        width: 14px;
        height: 15px;
      }
    }

    .iconLoader {
      display: none;
    }
  }

  .statusToUpdate {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $accentViolet;
    color: $white;
    width: fit-content;
    padding: 4px 6px;
    height: 24px;
    border-radius: 6px;

    .statusDescription {
      @include textCaptionMedium;
    }

    svg {
      margin: 0 8px 2px 0;
    }
  }

  .statusLastUpdate {
    @include textBodySmall;
    color: $gray;
    margin-left: 6px;
  }
}
