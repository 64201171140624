@import 'styles/mixin.scss';

.container {
  width: 346px;
  border-radius: 20px;
  background-color: $white;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15);
  animation: fadeInUp 0.5s cubic-bezier(0.9, 0.05, 0.25, 0.9);
  transition: all 0.5s cubic-bezier(0.9, 0.05, 0.25, 0.9);
  position: fixed;
  bottom: 50%;
  transform: translate(0, 50%);

  &.dataSourceContainer {
    animation: none;
    height: 483px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    bottom: 0;
    transform: translate(0);

    .content {
      height: 100%;
    }
  }

  &.buildContainer {
    animation: none;
  }

  &.buildContainer > *:not(.loaderContainer) {
    opacity: 0.5;
  }

  .header {
    height: 72px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    display: flex;
    align-items: center;
    padding: 0 8px 0 12px;
    overflow: hidden;
    transition: background-color 0.5s cubic-bezier(0.9, 0.05, 0.25, 0.9);

    .row {
      display: flex;
      flex-direction: row;
    }

    .nameContainer {
      padding-left: 14px;
      align-self: center;

      .nameStyles {
        @include textButtonLarge;
        width: 180px;
        overflow: hidden;
        line-height: normal;
        white-space: nowrap;
      }

      .infoStyles {
        @include textCaptionRegular;
        font-weight: 300;
      }
    }

    .infoIcon {
      display: flex;
      align-items: center;
      padding: 3px;

      svg {
        width: 26px;
        height: 26px;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 16px 16px 0 16px;
    background: #f5f5f5;
    height: 424px;

    .message {
      @include textBodySmall;
      max-width: 264px;
      width: fit-content;
      height: auto;
      border-radius: 16px;
      background-color: $white;
      margin-bottom: 6px;
      padding: 16px;
      overflow: hidden;
      text-overflow: ellipsis;

      &.outgoing {
        margin-top: 10px;
        align-self: flex-end;
        text-align: right;
        max-width: 240px;
        font-weight: 300;
        transition: all 0.5s cubic-bezier(0.9, 0.05, 0.25, 0.9);
      }

      .messageContent {
        max-height: 125px;
      }

      .messageContent,
      .messageForDataSourcesContent {
        display: block;
        overflow: hidden;
        text-overflow: clip;
      }

      .authorContainer {
        width: max-content;
        padding: 2px 4px;
        border-radius: 4px;
        margin-bottom: 6px;
        max-width: 100%;
        overflow: hidden;
        height: 20px;

        .authorStyles {
          @include textCaptionMedium;
        }
      }
    }

    .messageTime {
      @include textCaptionRegular;
      text-align: right;
      margin-bottom: 100px;
      color: $gray;
      padding-right: 16px;
      transition: all 0.5s cubic-bezier(0.9, 0.05, 0.25, 0.9);

      @media screen and (max-height: 820px) {
        margin-bottom: 50px;
      }

      @media screen and (max-height: 780px) {
        margin-bottom: 10px;
      }
    }
  }

  .inputContainer {
    display: flex;
    align-items: center;
    width: 100%;
    height: 82px;
    border-radius: 0 0 20px 20px;
    background: $white;
    margin: 0 auto;
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.1);

    .input {
      @include textBodySmall;
      display: flex;
      width: 250px;
      height: 52px;
      border-radius: 24px;
      background-color: $lightGrayBackground;
      align-items: center;
      padding: 0 16px;
      margin: 0 14px;
      color: $gray;
    }

    .sendButton {
      width: 54px;
      height: 54px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 34px;
        height: 34px;
      }
    }

    .closeButton {
      position: absolute;
      right: calc(-52px - 24px);
      width: 52px;
      height: 52px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  .infoMessageContainer {
    margin: 0 14px;
    padding: 16px 16px 24px;
    background-color: $white;
    border-radius: 24px;
    display: flex;
    flex-direction: column;

    .authorContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      align-self: flex-start;
      padding: 4px 8px;
      border-radius: 4px;
      margin-bottom: 8px;

      svg {
        height: 16px;
        width: 16px;
        margin-right: 8px;
      }
    }

    .textMessage {
      @include textBodyRegular;
      color: $darkGray;
    }
  }

  .loaderContainer {
    display: flex;
    justify-content: center;
    margin: 180px 0;

    svg {
      width: 30px;
      height: 30px;
    }
  }

  @media screen and (max-height: 720px) {
    transform: scale(0.8) translate(0, 70%);
  }
}

.botPreviewWelcomeContainer {
  position: fixed;
  transition: all 0.5s cubic-bezier(0.9, 0.05, 0.25, 0.9);
  width: 346px;

  .welcomeMessageContainer {
    display: flex;
    align-items: center;
    max-width: 346px;
    width: fit-content;
    border-radius: 16px;
    background: $white;
    box-shadow: 0px 4.8px 19.2px 0px rgba(48, 48, 48, 0.15);
    padding: 15px;
    text-align: end;
    margin-left: auto;

    p {
      @include textBodyRegular;
      max-height: 66px;
      margin-left: 12px;
      overflow: hidden;
    }

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .iconChatBubblePointer {
    display: flex;
    svg {
      margin: 0 25px 8px auto;
      width: 12px;
      height: 12px;
    }
  }

  .welcomeLogoContainer {
    margin-left: auto;
    width: 50px;
    height: 50px;

    .avatarContainer {
      width: 50px;
      height: 50px;

      svg {
        width: 32px;
        height: 32px;
      }
    }
  }

  &.largeChat {
    opacity: 1;
    transform: scale(1);
    top: 300px;

    @media screen and (max-height: 1100px) {
      top: 200px;
    }

    @media screen and (max-height: 820px) {
      top: 150px;
    }
  }

  &:not(.largeChat) {
    opacity: 0;
    transform: scale(0);
    top: 300px;

    @media screen and (max-height: 820px) {
      top: 250px;
    }
  }
}

.avatarContainer {
  width: 48px;
  height: 48px;
  border-radius: 100%;
  overflow: hidden;
  background-color: $lightViolet;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 26px;
    width: 26px;
  }

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    bottom: 30%;
  }
  100% {
    opacity: 1;
    bottom: 50%;
  }
}

// KEY ANIMATIONS AND BRAKING POINTS FOR SCREEN HEIGHTS - spaghetti code but it works :D

.container {
  &.largeChat {
    bottom: 200px !important;

    .outgoing,
    .messageTime {
      opacity: 0;
    }

    @media screen and (max-height: 880px) {
      bottom: -270px;
    }

    @media screen and (max-height: 820px) {
      bottom: -220px;
    }

    @media screen and (max-height: 780px) {
      bottom: -180px;
    }
  }
}

.mockButton {
  width: fit-content;
  padding: 8px;
  border: 1px solid black;
  border-radius: 12px;
  @include textBodySmall;
}

.inputWrapper {
  & > div {
    margin-bottom: 0;
  }

  input {
    width: 232px !important;
  }
}
