@import 'styles/mixin.scss';

.integrationCardContainer {
  padding: 16px;
  border-radius: 16px;
  border: 1px solid #dcdcdc;
  width: 300px;
  display: flex;
  flex-direction: column;
  position: relative;

  .integrationLogo {
    width: 42px;
    height: 42px;
    background-color: $lightGrayBackground;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;

    img {
      width: 24px;
      height: auto;
    }
  }

  .integrationContextMenu {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  .integrationName {
    @include textBodyMedium;
    color: $darkGray;
    margin-bottom: 8px;
  }

  .integrationDescription {
    @include textBodySmall;
    color: $gray;
    width: 90%;
    margin-bottom: 12px;
  }

  .integrationButton {
    @include textBodySmallMedium;
    margin-top: auto;
    width: fit-content;
    padding: 4px 8px;
    border-radius: 6px;
  }

  .integrationInstall {
    cursor: pointer;
    background: $accentViolet;
    color: $white;

    &:hover {
      background: $activeVioletHover;
    }
  }

  .integrationInstalled {
    background: $mainGreen;
    color: $lightGrayBackground;
  }

  .integrationComingSoon {
    background: $disabledGray;
    color: $lightGrayBackground;
  }
}
