@import 'styles/mixin.scss';

.wrapper {
  .modalFooter {
    .divider {
      height: 1px;
      background-color: $lightGray;
      margin: 24px 0;
    }

    .modalActionsWrapper {
      display: flex;
      justify-content: flex-end;
      gap: 16px;
    }
  }

  .modalTitle {
    @include textBodyRegular;
    font-weight: 500;
  }

  .imageInfo {
    display: flex;
    gap: 16px;
    margin: 24px 0;

    img {
      width: 68px;
      height: 68px;
      border-radius: 8px;
    }

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .imageTitle {
        @include textBodySmall;
      }

      .imageSize {
        @include textCaptionRegular;
        color: $gray;
      }
    }
  }
}

.textarea {
  height: 112px;
  margin-bottom: 24px;

  label {
    top: 14px;
  }

  div {
    height: 100% !important;
  }

  textarea {
    height: 100% !important;
  }
}

.foldersInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .info {
    @include textBodySmallMedium;

    p:last-child {
      @include textBodySmall;
      color: $gray;
    }
  }
}
