@import 'styles/mixin.scss';

.container {
  padding: 23px 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: $white;
  height: 64px;
  border-bottom: $lightGray 1.5px solid;

  &.borderBottom {
    border-bottom: 1px solid $lightGray;
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .titleStyles {
    @include subtitle;
    color: $darkGray;
  }

  .iconStyles {
    cursor: pointer;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 40px;
  }

  .userMenu {
    display: flex;
    gap: 24px;
  }

  .avatar {
    position: relative;
    // rest all default button styles
    appearance: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;

    .avatarImage {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      object-fit: contain;
    }
  }

  .statusWrapper {
    margin-right: auto;
    padding-left: 20px;
  }
}
