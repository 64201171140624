@import 'styles/mixin.scss';

.table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;

  thead {
    padding: 16px;
    background-color: $lightGrayBackground;

    tr {
      border-bottom: 1px solid $lightGray;

      .thWithIcon {
        display: flex;
        align-items: center;
        gap: 6px;
        min-width: 95px;
      }

      th {
        @include textBodySmall;
        padding: 16px 12px;
        text-align: left;
        font-weight: 500;
        color: $gray;
      }

      th:first-child {
        width: 30%;
        max-width: 334px;
        min-width: 130px;
        border-top-left-radius: 8px;
      }

      th:last-child {
        border-top-right-radius: 8px;
      }

      .cellMoreTh {
        width: 64px;
      }
    }
  }

  tbody {
    @include textBodySmall;

    tr {
      border-top: 1px solid $lightGray;
      border-bottom: 1px solid $lightGray;

      td {
        min-width: 100px;
        padding: 0 12px;
        color: $darkGray;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        .grayText {
          color: $gray;
        }
      }

      .tdPreview {
        position: relative;
        height: 48px;
        display: flex;
        gap: 12px;
        align-items: center;
        margin: 8px 0px;

        .previewContainer {
          position: relative;
          width: 40px;
          height: 40px;
          border: 1px solid $lightGray;
          border-radius: 8px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;

          a {
            display: block;
            width: 40px;
            height: 40px;
          }

          span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-59%, -50%);
            @include textBodySmall;
            font-weight: 700;
            color: $gray;
          }

          svg {
            width: 20px;
            height: 20px;
          }
        }

        .info {
          min-width: 50px;

          p {
            min-width: 50px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }

      &:hover {
        background-color: $lightViolet;
      }

      &:last-child {
        border: 1px;
      }
    }
  }
}

.switchWrapper {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background: $accentViolet;

      &:before {
        transform: translateX(20px);
      }
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 26px;

    &:before {
      position: absolute;
      content: '';
      height: 20px;
      width: 20px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      transition: 0.4s;
      border-radius: 50%;
    }
  }
}

.iconMoreButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border: none;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    background-color: $lightGrayBackground;
    border-radius: 50%;
  }
}

.cellMore {
  width: 16px;
}

.folderPreview {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
}

.listUploadButtonWrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;

  border: 1px solid $lightGray;
  border-radius: 12px;
  @include textButtonLarge;

  .imageWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    width: 96px;
    height: 96px;
    border-radius: 50%;
    cursor: pointer;

    svg {
      width: 64px;
      height: 64px;
    }

    &:hover {
      background-color: $lightViolet;
    }
  }
}
