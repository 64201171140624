@import 'styles/mixin.scss';

.detailsContainer {
  width: 100%;
  padding: 40px;

  .detailsHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    .detailsHeaderInfo {
      .detailsHeaderInfoName {
        @include subtitle;
        color: $darkGray;
        margin-bottom: -4px;
      }

      .detailsHeaderInfoWebsite {
        @include textBodyRegular;
        color: $gray;
      }
    }
  }
}

.techbotDetailsCardContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
  grid-gap: 24px;
}

.detailsGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  margin-bottom: 24px;

  @media (max-width: $SMALL_DESKTOP) {
    grid-template-columns: 1fr;
  }

  .detailsContentConainer {
    margin-top: 24px;
    border-radius: 8px;
    border: 1px solid $lightGray;
    padding: 24px 32px;

    .detailsContentHeader {
      display: flex;
      justify-content: space-between;
      padding-bottom: 24px;
      border-bottom: 1px solid $lightGray;

      .detailsContentHeaderTitle {
        @include textBodyMedium;
        color: $darkGray;
        align-self: center;
      }
    }

    .detailsContentDataConainer {
      .detailsContentDataLabel {
        @include textButtonLarge;
        color: $darkGray;
        margin: 16px 0;
      }

      .detailsContentDataContent {
        display: flex;
        border-radius: 8px;
        border: 1px solid $lightGray;
        padding: 16px;
        align-items: center;

        svg {
          margin-right: 16px;
        }

        .detailsContentDataContentLength {
          @include textBodySmallMedium;
          margin-right: 5px;
          color: $darkGray;
        }

        .detailsContentDataContentDescription {
          @include textBodySmall;
          color: $gray;
        }
      }
    }
  }
}
