@import 'styles/mixin.scss';

.selfLearningContainer {
  display: flex;
  gap: 14px;
  flex-wrap: wrap;
}

.zendeskTicketContainer {
  width: 420px;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  border: 1px solid $lightGray;
  padding: 16px;

  .zendeskTicketText {
    .zendeskTicketTitle {
      @include textBodyMedium;
      color: $darkGray;
      margin-bottom: 4px;
    }

    .zendeskTicketDescription {
      @include textBodyRegular;
      color: $gray;
      margin-bottom: 16px;
    }
  }

  .syncContainer {
    display: flex;
    align-items: center;

    .syncDate {
      @include textBodySmall;
      margin-left: 12px;
      color: $gray;
      text-align: center;
    }
  }
}
