@import 'styles/mixin.scss';

.dropdownContent {
  min-width: 220px;
  width: 100%;
  font-size: 16px !important;
  font-weight: 400 !important;

  li {
    padding: 12px !important;

    &:first-child {
      padding: 0 !important;
    }

    a {
      @include textBodyRegular;
      width: 100% !important;
      height: 100% !important;
      padding: 12px !important;

      display: flex;
      align-items: center;
      color: $darkGray !important;
      text-decoration: none !important;

      svg {
        width: 20px !important;
        height: 20px !important;
        margin-right: 8px !important;
      }
    }

    &:hover {
      background-color: $lightGrayBackground !important;
    }

    svg {
      width: 20px !important;
      height: 20px !important;
      margin-right: 8px !important;
    }

    .iconArrow {
      margin-left: auto;
      margin-right: 0px !important;
    }
    
  }
}
