@import 'styles/mixin.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .title {
    @include textButtonLarge;
    font-weight: 500;
    margin-bottom: 8px;
  }

  .description {
    @include textBodyRegular;
    color: $gray;
    margin-bottom: 24px;
  }

  button:first-of-type {
    width: 100%;
    margin-bottom: 8px;
  }

  button:last-of-type {
    width: 100%;
    border: none;
    background-color: $white;
    color: $gray;

    &:hover {
      background-color: $lightGrayBackground;
      color: $gray;
    }
  }
}
