@import 'styles/mixin.scss';

.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: $Z_INDEX_MODAL;

  .contentStyles {
    background-color: $white;
    padding: 40px 56px;
    border-radius: 12px;
    max-width: 556px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    max-height: 90vh;
    overflow-y: auto;

    .titleStyles {
      @include textHeader4;
      margin-bottom: 8px;
      color: $graphite;
      font-weight: 500;
    }

    .messageStyles {
      @include textBodyRegular;
      color: $gray;
      margin-bottom: 15px;
    }

    .addContentInput {
      margin-bottom: 0px;
    }

    .addContentTextarea {
      margin-bottom: 10px;

      label {
        top: 14px;
      }

      & > div {
        height: 144px;
      }

      textarea {
        height: 144px;
      }
    }
  }

  .buttonsGroup {
    margin-top: 24px;
    justify-content: flex-end;
    gap: 16px;
    display: flex;
  }
}
