@import 'styles/mixin.scss';

.imagesContainer {
  height: 100%;

  .controlPanel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .controlPanelTitle {
      @include textBodyMedium;
      color: $darkGray;
    }

    .tab {
      @include textCaptionMedium;
      color: $gray;
      height: 26px;
      border-radius: 4px;
      margin-left: 8px;
      padding: 4px 8px;
      cursor: pointer;
      position: relative;

      &:hover {
        background-color: $lightGrayBackground;
      }
    }

    .tabActive {
      background-color: $lightViolet;
      color: $accentViolet;
    }
  }
}

.groupWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.vector {
  height: 12px;
  border: 1px solid $lightGray;
}

.selectionGroup {
  display: flex;
  gap: 8px;
}

.option {
  color: $gray;
  cursor: pointer;
  transition:
    background-color 0.3s,
    color 0.3s;
  position: relative;

  input[type='radio'] {
    display: none;
  }

  input[type='radio']:checked + span {
    background-color: $lightViolet;
    color: $accentViolet;
  }

  span {
    @include textCaptionMedium;
    display: flex;
    align-items: center;
    gap: 8px;

    padding: 6px;
    border-radius: 8px;
    font-size: 12px;
  }

  &:hover span {
    background-color: $lightGrayBackground;
  }
}

.noNameButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  padding: 8px;
  border: 1px solid $lightViolet;
  border-radius: 10px;
  background-color: $lightViolet;
  cursor: pointer;
}
