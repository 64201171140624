@import 'styles/mixin.scss';

.cardWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 8px 0;

  .closeButton {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    border: none;
    background-color: $white;
    transition: background-color 0.3s;

    &:hover {
      background-color: $lightGrayBackground;
    }
  }

  .cardInfo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 8px;
    width: 90%;

    .info {
      max-width: 100%;
      margin-left: 12px;
      overflow: hidden;
    }

    .title {
      @include textBodySmall;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $darkGray;
    }

    .size {
      @include textCaptionRegular;
      color: $gray;
    }
  }

  .previewFormat {
    position: relative;
    width: 48px;
    height: 48px;
    border-radius: 8px;
    background-color: #d9d9d9;
    flex-shrink: 0;

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-56%, -50%);
      @include textBodySmall;
      font-weight: 700;
      color: $gray;
    }
  }
}

.buttonsWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 10%;

  & button:first-of-type {
    margin-right: 8px;
  }

  svg {
    width: 24px;
    height: 24px;
  }
}
