@import 'styles/mixin.scss';

.container {
  max-width: 488px;
  padding: 16px;
  border-radius: 8px;
  border: 1.5px solid $lightGray;

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .previewWrapper {
    border: 1px dashed $lightGray;
    border-radius: 8px;
    margin-right: 16px;
    cursor: pointer;

    .preview {
      height: 48px;
      width: 48px;
      background-color: $lightGrayBackground;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      margin: 3px;

      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }

    svg {
      height: 24px;
      width: 24px;
    }
  }

  .content {
    .info {
      @include textCaptionRegular;
      color: #000;
      opacity: 0.6;
      margin: 0 0 8px;
    }

    .buttonUpload {
      @include textCaptionMedium;
      background-color: $accentViolet;
      color: $white;
      border-radius: 6px;
      padding: 4px 6px;
      cursor: pointer;
    }
  }
}

.infoTextStyles {
  @include textBodySmallMedium;
  margin-left: 16px;
  color: $gray;
}
