@import 'styles/mixin.scss';

.radioSelectorContainer {
  width: 50%;
  .radioItem {
    padding: 16px 0;
    border-bottom: 1px solid $lightGray;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .radioItemTitle {
      @include textBodySmallMedium;
      color: $darkGray;
    }

    .radioItemDescription {
      @include textBodySmall;
      color: $gray;
      margin-right: 50px;
    }

    .separator {
      margin-bottom: 16px;
    }

    .handoffInputContainer {
      max-width: 270px;
    }
  }
}

.customRadio {
  appearance: none;
  width: 20px;
  height: 20px;
  background: white;
  border: 1.5px solid $mediumGray;
  border-radius: 50%;
  outline: none;
  box-sizing: border-box;
  cursor: pointer;

  &:checked {
    border: 1.5px solid $accentViolet;

    &:after {
      background: $accentViolet;
      border: 1.5px solid $accentViolet;
    }
  }

  &:after {
    content: '';
    display: block;
    width: 9px;
    height: 9px;
    margin: 4.4px;
    border-radius: 50%;
    box-sizing: border-box;
  }
}
