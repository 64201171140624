@import 'styles/mixin.scss';

.knowledgeContainer {
  .knowledgeHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;

    .knowledgeHeaderContent {
      @include textBodyMedium;
      color: $darkGray;
    }

    .buttonContainer {
      display: flex;
      gap: 12px;
    }
  }
}
