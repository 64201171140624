@import 'styles/mixin.scss';

.authInstructionContainer {
  @include modalContainer;

  .authInstructionContent {
    @include modalContent;

    .authInstructionHeaderContainer {
      display: flex;
      justify-content: space-between;

      .authInstructionHeaderTitle {
        @include textBodyMedium;
        color: $graphite;
      }

      .authInstructionCloseIcon {
        cursor: pointer;
      }
    }

    .authInstructionStep {
      @include textBodySmall;
      color: $gray;
      margin: 19px 0 25px 0;
    }

    .authBenefits {
      @include textBodySmall;
      color: $gray;
      margin-bottom: 25px;
    }

    .authBenefit {
      @include textBodySmallMedium;
      color: $darkGray;
      margin-bottom: 25px;
    }

    .authInstructionTitle {
      @include textBodyMedium;
      color: $darkGray;
      margin-bottom: 8px;
    }

    .authInstructionList {
      padding-bottom: 25px;
      border-bottom: 1px solid $lightGray;
      li {
        @include textBodySmall;
        color: $gray;
        list-style-type: none;

        a {
          color: $gray;
          text-decoration-line: underline;
          &:visited {
            color: $gray;
          }
        }
      }
    }

    .authInstructionButtonContainer {
      margin-top: 25px;
      display: flex;
      justify-content: flex-end;
      gap: 16px;
    }
  }
}
