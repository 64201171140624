@import 'styles/mixin.scss';

.handoffContainer {
  width: 90%;
  padding: 40px;
  overflow: auto;

  .handoffNoteContainer {
    max-width: 442px;
  }

  .separator {
    margin: 40px 0;
    width: 100%;
    border-bottom: 1px solid $lightGray;
  }
}
