@import 'styles/mixin.scss';

.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: $Z_INDEX_MODAL;

  .contentStyles {
    background-color: $white;
    padding: 24px;
    border-radius: 12px;
    max-width: 295px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .topIconStyles {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
    }

    .titleStyles {
      @include textHeader4;
      text-align: center;
      margin-bottom: 16px;
      color: $graphite;
    }

    .messageStyles {
      @include textBodyRegular;
      text-align: center;
      color: $gray;
      margin-bottom: 24px;
    }

    .buttonsGroup {
      .buttonsSeparator {
        margin-top: 12px;
      }
    }
  }
}
