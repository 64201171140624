@import 'styles/mixin.scss';

.table {
  width: 100%;
  border-collapse: collapse;

  thead {
    padding: 16px;
    background-color: $lightGrayBackground;

    tr {
      .thWithIcon {
        display: flex;
        align-items: center;
        gap: 6px;
        min-width: 95px;
      }

      th {
        @include textBodySmall;
        padding: 16px;
        text-align: left;
        font-weight: 500;
        color: $gray;
      }

      th:first-child {
        border-top-left-radius: 8px;
      }

      th:last-child {
        border-top-right-radius: 8px;
      }
    }
  }

  tbody {
    @include textBodySmall;

    tr {
      border-top: 1px solid $lightGray;
      border-bottom: 1px solid $lightGray;
      height: 56px;
      td {
        padding: 12px 16px;
        color: $darkGray;

        .title {
          color: $darkGray;
        }

        .grayText {
          color: $gray;
        }
      }

      .tdPreview {
        position: relative;
        height: 48px;
        display: flex;
        gap: 12px;
        align-items: center;

        .previewContainer {
          position: relative;
          width: 40px;
          height: 40px;
          border-radius: 8px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 40px;
            height: 40px;
            border-radius: 8px;
          }

          div {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }

      &:hover {
        background-color: $lightViolet;
      }

      &:last-child {
        border: 1px;
      }
    }
  }
}

.switchWrapper {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background: $accentViolet;

      &:before {
        transform: translateX(20px);
      }
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 26px;

    &:before {
      position: absolute;
      content: '';
      height: 20px;
      width: 20px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      transition: 0.4s;
      border-radius: 50%;
    }
  }
}

.iconMoreButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border: none;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    background-color: $lightGrayBackground;
    border-radius: 50%;
  }
}

.cellMore {
  width: 16px;
}
