@import 'styles/mixin.scss';

.portalPreviewContainer {
  display: flex;
  flex: 1;

  .portalPreviwContent {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 40px;
    overflow-y: auto;
    overflow-x: hidden;
    width: 45%;
    max-width: 568px;
    gap: 24px;

    .copyContainer {
      max-width: 488px;
      width: 100%;
      height: 286px;

      .title {
        @include textBodyMedium;
        color: $graphite;
      }

      .description {
        @include textBodyRegular;
        color: $gray;
        padding: 4px 0 24px 0;
      }

      .buttonContainer {
        margin-top: 32px;
        display: flex;
        justify-content: space-between;
      }

      .authContainer {
        margin: 20px 0px 20px;
        border: 1px solid $lightGray;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        padding: 16px;

        .authLabel {
          @include textBodySmallMedium;
        }

        .authDescription {
          @include textBodyRegular;
          color: $gray;
        }

        .authDescriptionContainer {
          display: flex;
          justify-content: space-between;
        }
      }

      @media (max-width: 1275px) {
        width: 400px;
      }
    }
  }

  .testIconContainer {
    svg {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 55%;
      height: auto;

      @media (min-width: 1475px) {
        width: 50%;
      }
    }

    @media (max-width: 1275px) {
      display: none;
    }
  }

  .preview {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: $lightGrayBackground;
    justify-content: center;
    align-items: center;

    svg {
      transform: scale(1.2);

      @media (max-height: 750px), (max-width: 1500px) {
        transform: scale(1);
      }
    }
  }
}

.table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;

  thead {
    padding: 16px;
    background-color: $lightGrayBackground;

    tr {
      border-bottom: 1px solid $lightGray;

      th {
        @include textBodySmall;
        font-family: 'Manrope';
        padding: 16px 12px;
        text-align: left;
        font-weight: 500;
        color: $gray;
      }

      th:first-child {
        width: 30%;
        max-width: 356px;
        min-width: 130px;
        border-top-left-radius: 8px;
      }

      th:last-child {
        border-top-right-radius: 8px;
      }

      .cellMoreTh {
        width: 64px;
      }
    }
  }

  tbody {
    @include textBodySmall;
    font-family: 'Manrope';

    tr {
      border-top: 1px solid $lightGray;
      border-bottom: 1px solid $lightGray;

      td {
        min-width: 100px;
        padding: 0 12px;
        color: $darkGray;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &:hover {
        background-color: $lightViolet;
      }

      &:last-child {
        border: 1px;

        &.noViewers {
          height: 70px;
        }
      }

      &.pendingInvitation > td {
        color: $gray;
      }
    }
  }
}
