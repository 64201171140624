@import 'styles/mixin.scss';

.buttonIcon {
  cursor: pointer;
  position: relative;

  svg {
    height: 24px;
    width: 24px;
  }
}

.optionsContainer {
  position: absolute;
  top: 25px;
  right: 15px;
  background-color: $white;
  border-radius: 8px;
  box-shadow:
    0px 10px 20px 0px rgba(37, 42, 90, 0.05),
    0px 1px 6px 0px rgba(25, 25, 25, 0.08);
  width: 190px;
  z-index: 1;

  .optionGroup {
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: $white;
    padding: 12px;

    .optionName {
      @include textBodySmallMedium;
      color: $darkGray;
    }
    svg {
      margin-right: 12px;
    }

    &:first-child {
      border-radius: 8px 8px 0 0;
    }

    &:last-child {
      border-radius: 0 0 8px 8px;
    }

    &:only-child {
      border-radius: 8px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #dcdcdc;
    }

    &:hover {
      background-color: $lightGrayBackground;
    }
  }
}
