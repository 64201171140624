@import 'styles/mixin.scss';

.integrationsContainer {
  padding: 40px;
  width: 100%;

  .integrationsHeader {
    @include textBodyMedium;
    color: $darkGray;
    padding-bottom: 24px;
  }

  .integrationCardsContainer {
    margin-top: 24px;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
}
