@import 'styles/mixin.scss';

.techbotDetailsCard {
  position: relative;
  padding: 16px;
  align-items: flex-start;
  gap: 16px;
  border-radius: 12px;
  background: $white;
  border: 1.5px solid $lightGray;
  min-width: 360px;
  height: 166px;
  margin-right: 24px;
  display: flex;
  justify-content: space-between;

  .techbotDetailsCardLabel {
    @include textBodyMedium;
    margin-bottom: 8px;
  }

  .techbotDetailsCardDescription {
    @include textBodyRegular;
    color: $gray;
  }

  .switchContainer {
    position: absolute;
    bottom: 16px;
    left: 4px;
  }

  .usageContainer {
    @include textHeader3;
    position: absolute;
    bottom: 16px;
    color: $darkGray;
  }
}
