@import 'styles/mixin.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 190px;
  padding: 16px;
  border: 1px solid $lightGray;
  border-radius: 12px;
  cursor: pointer;

  .cardInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .title {
      @include textBodySmall;
      color: $darkGray;
    }

    .size {
      @include textCaptionRegular;
      color: $gray;
    }
  }
}
